
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;

  &.contakt {
    [data-overlay]::before {
      background: #24212e;
    }

    .navbar-brand img {
      max-height: 20px;
    }

    .footer-logo img {
      max-height: 20px;
    }
  }

  .feature-image-container {
    padding: 6px;
    background-color: #eee;
    border-radius: 8px;
  }
}

@import "./bd_callouts.scss";