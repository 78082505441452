
.feature-1 {

  .feature-icon {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 1.75rem;
    color: $color-text-secondary;

    /**
     * modified to use inline base64 image content
     */
    img {
      max-height: 50px;
      display: block;
      margin: 0 auto;
    }
  }

}


.feature-2 {

  .feature-icon {
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 1.5rem;
    color: $color-text-secondary;
  }

  h5, h6 {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
  }
}
